import React from "react";

const CookiesPolicy = () => (
    <div className="pw_legals_container">
        <div>
            <div>
                <h4>POL&Iacute;TICA DE COOKIES</h4>
                <p>La entidad ANAIS OSUNA DELAUNOIS, con NIF/CIF: 41541276X, domicilio en la CAMINO DE CAN PASTILLA N&ordm; 68 LOCAL C 07610 PALMA, BALEARES, direcci&oacute;n de correo electr&oacute;nico: ANAISDELAUNOIS1@GMAIL.COM y web: www.latelierbyanais.com informa al usuario de manera expresa de que, de conformidad con el art. 22.2 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico ( LSSI), esta web utiliza cookies con el objetivo de prestar un mejor servicio y proporcionarle una mejor experiencia de navegaci&oacute;n.</p>
                <p>En la presente Pol&iacute;tica de Cookies encontrar&aacute; la descripci&oacute;n y finalidad de cada una de las cookies utilizadas, as&iacute; como su nombre, el servidor de env&iacute;o, su caducidad e informaci&oacute;n de c&oacute;mo verificar si existe transferencia internacional de datos.</p>
                <p>Queremos informarle de manera clara y precisa sobre las cookies que utilizamos, detallando a continuaci&oacute;n: qu&eacute; es una cookie, para qu&eacute; sirve, qu&eacute; tipos de cookies utilizamos, cu&aacute;l es su finalidad y c&oacute;mo puede configurarlas o deshabilitarlas si as&iacute; lo desea.</p>
                <p><strong>&iquest;Qu&eacute; es una &quot;cookie&quot;?</strong></p>
                <p>Una &quot;cookie&quot; es un fichero que se descarga en los sitios web y que determinadas p&aacute;ginas web env&iacute;an al navegador, y que se almacenan en el terminal del usuario, el cual puede ser un ordenador personal, un tel&eacute;fono m&oacute;vil, una tableta, etc. Estos archivos permiten que el sitio web recuerde informaci&oacute;n sobre su visita, como el idioma y las opciones preferidas, lo que le permite recuperar informaci&oacute;n sobre la navegaci&oacute;n que se efect&uacute;a desde su equipo. De esta manera se pretende facilitar su pr&oacute;xima visita y hacer que el sitio, al personalizar su contenido, resulte m&aacute;s &uacute;til.</p>
                <p><strong>&iquest;C&oacute;mo se utilizan las &quot;cookies&quot;?</strong></p>
                <p>Al navegar por esta p&aacute;gina web, el usuario est&aacute; aceptando que se puedan instalar &quot;cookies&quot; en su terminal, las cuales nos permiten conocer la siguiente informaci&oacute;n:</p>
                <p>Reconocerle como usuario.</p>
                <p>Obtener informaci&oacute;n sobre tus h&aacute;bitos de navegaci&oacute;n.</p>
                <p>Personalizar la forma en que se muestra el contenido.</p>
                <p>Informaci&oacute;n estad&iacute;stica del uso de la web.</p>
                <ul>
                    <li>El login del usuario para mantener la sesi&oacute;n activa en la web.</li>
                    <li>El formato de la web preferente en el acceso desde dispositivos m&oacute;viles.</li>
                    <li>Las &uacute;ltimas b&uacute;squedas realizadas en los servicios de la web y datos de personalizaci&oacute;n de estos servicios.</li>
                    <li>Informaci&oacute;n sobre los anuncios que se muestran al usuario.</li>
                    <li>Informaci&oacute;n de las encuestas en las que ha participado el usuario.</li>
                    <li>Datos de la conexi&oacute;n con las redes sociales para los usuarios que acceden con su usuario de Facebook o</li>
                </ul>
                <p>Twitter.</p>
                <p>A continuaci&oacute;n, podr&aacute; encontrar una clasificaci&oacute;n de las cookies que existen y pueden llegar a ser utilizadas en nuestra web, teniendo en cuenta que una misma cookie puede estar incluida en m&aacute;s de una categor&iacute;a.</p>

                <h6>Tipos de Cookies:</h6>

                <p>A.-Tipos de cookies seg&uacute;n el plazo de tiempo que permanecen activadas. Seg&uacute;n el plazo de tiempo que permanecen activadas en el equipo terminal, esta web puede utilizar tanto &quot;cookies&quot; temporales de sesi&oacute;n como &quot;cookies&quot; permanentes.</p>
                <p>1.- &quot;Cookies&quot; de sesi&oacute;n: las &quot;cookies&quot; de sesi&oacute;n almacenan datos &uacute;nicamente mientras el usuario accede a la web. Se suelen emplear para almacenar informaci&oacute;n que s&oacute;lo interesa conservar para la prestaci&oacute;n del servicio solicitado por el usuario en una sola ocasi&oacute;n y desaparecen al terminar la sesi&oacute;n.</p>
                <p>2.- &quot;Cookies&quot; persistentes/permanentes: las &quot;cookies&quot; persistentes/permanentes almacenan los datos en el terminal para que sean accedidos y utilizados en m&aacute;s de una sesi&oacute;n. Los datos que siguen almacenados en el terminal pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios a&ntilde;os.</p>
                <p>B.-Tipos de cookies seg&uacute;n su finalidad. Seg&uacute;n la finalidad para la que se traten los datos obtenidos a trav&eacute;s de las &quot;cookies&quot;, la web puede utilizar:</p>
                <p>1.&quot;Cookies&quot; estrictamente necesarias: las cookies estrictamente necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Esta categor&iacute;a solo incluye cookies que garantizan funcionalidades b&aacute;sicas y caracter&iacute;sticas de seguridad del sitio web. Estas cookies no almacenan ninguna informaci&oacute;n personal. 2.-&quot;Cookies&quot; t&eacute;cnicas: son aquellas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web, plataforma o aplicaci&oacute;n, y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de videos o sonido o compartir contenidos a trav&eacute;s de redes sociales.</p>
                <p>3.-&quot;Cookies&quot; de personalizaci&oacute;n: son aquellas que permiten al usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del usuario como, por ejemplo, ser&iacute;an el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, la geolocalizaci&oacute;n del terminal y la configuraci&oacute;n regional desde donde se accede al servicio.</p>
                <p>4.-&quot;Cookies&quot; anal&iacute;ticas: son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar el n&uacute;mero de usuarios, y as&iacute; realizar la medici&oacute;n y an&aacute;lisis estad&iacute;stico de la utilizaci&oacute;n que hacen los usuarios del servicio ofertado. Para ello se analiza su navegaci&oacute;n en nuestra p&aacute;gina web con el fin de mejorar la oferta de productos o servicios disponibles.</p>
                <p>5. &quot;Cookies&quot; de publicidad comportamental: son aquellas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una p&aacute;gina web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</p>
                <p>C.-Tipos de cookies seg&uacute;n la entidad que las gestione. Seg&uacute;n qui&eacute;n sea la entidad que gestione el equipo o dominio desde donde se env&iacute;an las cookies y trate los datos que se obtengan, podemos distinguir:</p>
                <p>1.- &quot;Cookies&quot; propias: son aquellas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor, y desde el que se presta el servicio solicitado por el usuario.</p>
                <p>2.- &quot;Cookies&quot;de terceros: son aquellas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos trav&eacute;s de las cookies. En algunas p&aacute;ginas web se pueden instalar &quot;cookies&quot; de terceros que permiten gestionar y mejorar los servicios ofrecidos. Por ejemplo, servicios estad&iacute;sticos de Google Analytics y Comscore.</p>
                <p>Al navegar o utilizar nuestros servicios, el usuario acepta de manera expresa el uso que hacemos de las &quot;cookies&quot;. Sin embargo, el usuario tiene la opci&oacute;n de impedir la generaci&oacute;n de &quot;cookies&quot; y la eliminaci&oacute;n de las mismas mediante la selecci&oacute;n de la correspondiente opci&oacute;n en su navegador. En caso de bloquear el uso de &quot;cookies&quot; en su navegador, es posible que algunos servicios o funcionalidades de la p&aacute;gina web no est&eacute;n disponibles.</p>
                <p>La Entidad utiliza en este sitio web las siguientes cookies para las finalidades que a continuaci&oacute;n se exponen:</p>

                <h6>COOKIES PROPIAS</h6>

                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>
                                <p><strong>CookieID</strong><br /> (ID de la cookie y como aparece en la cach&eacute; del navegador)</p>
                            </td>
                            <td>
                                <p><strong>Tipo</strong><br /> (Propia o de terceros)</p>
                            </td>
                            <td>
                                <p><strong>Expiraci&oacute;n</strong><br /> (Fecha de caducidad de la cookie una vez instalada)</p>
                            </td>
                            <td>
                                <p><strong>Dominio</strong><br /> (Con qu&eacute; dominio se asocia la cookie)</p>
                            </td>
                            <td>
                                <p><strong>Descripci&oacute;n</strong><br /> (Funcionamiento y finalidad de la cookie)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>acceptCookies</p>
                            </td>
                            <td>
                                <p>Propia</p>
                            </td>
                            <td>
                                <p>Sesi&oacute;n</p>
                            </td>
                            <td>
                                <p>www.latelierbyanais.com</p>
                            </td>
                            <td>
                                <p>NECESARIA</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p><strong>COOKIES DE TERCEROS GOOGLE</strong></p>
                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>
                                <p><strong>CookieID</strong><br /> (ID de la cookie y como aparece en la cach&eacute; del navegador)</p>
                            </td>
                            <td>
                                <p><strong>Tipo</strong><br /> (Propia o de terceros)</p>
                            </td>
                            <td>
                                <p><strong>Expiraci&oacute;n/Duraci&oacute;n</strong><br /> (Fecha de caducidad/Duraci&oacute;n de la cookie una vez instalada)</p>
                            </td>
                            <td>
                                <p><strong>Dominio</strong><br /> (Con qu&eacute; dominio se asocia la cookie)</p>
                            </td>
                            <td>
                                <p><strong>Descripci&oacute;n</strong><br /> (Funcionamiento y finalidad de la cookie)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>1PJAR</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente Caducidad/Duraci&oacute;n: 1 mes</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANAL&Iacute;TICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>CONSENT</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 20 a&ntilde;os</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>T&Eacute;CNICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_APISID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANAL&Iacute;TICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_NID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 6 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PREFERENCIAS/PERSONALIZACI&Oacute;N</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_SID _HSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>TECNICA.-</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_SSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANALITICA/PREFERENCIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_NID _SID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 6 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA.-,</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_SIDCC</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 3 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANAL&Iacute;TICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>SEARCH_SAMESITE</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 6 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>T&Eacute;CNICA.-,</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_SID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2A&Ntilde;OS</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANAL&Iacute;TICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_SAPISID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 A&Ntilde;OS</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>ANAL&Iacute;TICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>_OTZ</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 1 MES</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICIDAD</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-3PAPISID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-3PSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-3PSIDCC</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-APISID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-HSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 2 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-SSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor Google</p>
                            </td>
                            <td>
                                <p>Persistente</p>
                                <p>Caducidad/Duraci&oacute;n: 6 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.google.com</a></p>
                            </td>
                            <td>
                                <p>PUBLICITARIA</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6>COOKIES DE TERCEROS YOUTUBE</h6>
                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td>
                                <p><strong>CookieID</strong><br /> (ID de la cookie y como aparece en la cach&eacute; del navegador)</p>
                            </td>
                            <td>
                                <p><strong>Tipo</strong><br /> (Propia o de terceros)</p>
                            </td>
                            <td>
                                <p><strong>aducidad/Duraci&oacute;n</strong><br /> (Fecha de caducidad/Duraci&oacute;n de la cookie una vez instalada)</p>
                            </td>
                            <td>
                                <p><strong>Dominio</strong><br /> (Con qu&eacute; dominio se asocia la cookie)</p>
                            </td>
                            <td>
                                <p><strong>Descripci&oacute;n</strong><br /> (Funcionamiento y finalidad de la cookie)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>YSC</p>
                            </td>
                            <td>
                                <p>Terceros</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Sesi&oacute;n</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>PERSONALIZACI&Oacute;N O PREFERENCIAS</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>VISITOR_INFO1_LIVE</p>
                            </td>
                            <td>
                                <p>Terceros</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Persistente.</p>
                                <p>Caducidad/Duraci&oacute;n 6 meses</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>PERSONALIZACI&Oacute;N O PREFERENCIAS</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>GPS</p>
                            </td>
                            <td>
                                <p>Terceros</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Persistente.</p>
                                <p>Caducidad/Duraci&oacute;n 1 dia</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>PERSONALIZACI&Oacute;N O PREFERENCIAS</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>PREF</p>
                            </td>
                            <td>
                                <p>Terceros</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Persistente.</p>
                                <p>Caducidad/Duraci&oacute;n 10 a&ntilde;os.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>PERSONALIZACI&Oacute;N O PREFERENCIAS</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>SID, NID, HSID,LOGIN_INFO, NID, APISID, CONSENT, HSID, SAPISID, SID, SSID, 1P_JAR</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Persistente.</p>
                                <p>Caducidad/Duraci&oacute;n: 6 meses/2 a&ntilde;os</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>T&Eacute;CNICA</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>__Secure-3PAPISID, __Secure-3PSID, __Secure-APISID, __Secure-HSID, __Secure-SSID</p>
                            </td>
                            <td>
                                <p>Terceros.</p>
                                <p>Proveedor</p>
                                <p>Youtube</p>
                            </td>
                            <td>
                                <p>Persistente.</p>
                                <p>Caducidad/Duraci&oacute;n:</p>
                                <p>2 a&ntilde;os</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.es/intl/es/policies/technologies/types/">.youtube.com</a></p>
                            </td>
                            <td>
                                <p>T&Eacute;CNICA</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>Las cookies no contienen informaci&oacute;n que permita por s&iacute; sola identificar a un usuario concreto. Respecto a nosotros, la informaci&oacute;n obtenida solo podr&aacute; ser asociada a un usuario concreto en caso de que dicho usuario est&eacute; identificado en la web. De ser as&iacute;, dichos datos ser&aacute;n tratados de conformidad la pol&iacute;tica de privacidad de la web, cumpliendo en todo momento con las exigencias de la normativa vigente en materia de protecci&oacute;n de datos de car&aacute;cter personal. En cualquier momento los usuarios afectados podr&aacute;n ejercitar su derecho a acceder, rectificar y suprimir los datos, as&iacute; como otros derechos como se explica en la citada pol&iacute;tica de privacidad. Para m&aacute;s informaci&oacute;n debe acceder a nuestra Pol&iacute;tica de protecci&oacute;n de datos.</p>
                <p>Las cookies ser&aacute;n utilizadas &uacute;nica y exclusivamente durante el tiempo necesario para cumplir con las finalidades indicadas en cada cookie. En relaci&oacute;n con las cookies de terceros, los plazos de conservaci&oacute;n podr&aacute;n ser consultados en las pol&iacute;ticas de privacidad y pol&iacute;ticas de cookies de los terceros. Una vez caducada la cookie, podr&aacute; ser renovada si el usuario vuelve a aceptar su utilizaci&oacute;n.</p>
                <p>Puede informarse de las transferencias a terceros pa&iacute;ses que en su caso realizan los terceros identificados en esta pol&iacute;tica de cookies en sus correspondientes pol&iacute;ticas.</p>
                <p><strong>&iquest;C&oacute;mo administrar &quot;cookies&quot; en el navegador?</strong></p>
                <p>El usuario tiene la opci&oacute;n de permitir, bloquear o eliminar las &quot;cookies&quot; instaladas en su equipo mediante la configuraci&oacute;n de las opciones del navegador instalado en su terminal.</p>
                <p>&iquest;<strong>C&oacute;mo se autoriza a la entidad para el uso de las cookies de este sitio web?</strong></p>
                <p>En relaci&oacute;n con la utilizaci&oacute;n de cookies de este sitio web descritos en el apartado anterior, el usuario autoriza y consiente su uso de la siguiente forma: Cuando el usuario accede a cualquier p&aacute;gina de la web, ver&aacute; un aviso donde se indica que la p&aacute;gina web de ANAIS OSUNA DELAUNOIS utiliza cookies, pudiendo el usuario aceptar o rechazar el uso de las mismas a trav&eacute;s de la configuraci&oacute;n de su navegador. Si el usuario no configura su navegador para que las cookies no se activen, al navegar por el sitio web de ANAIS OSUNA DELAUNOIS y utilizar sus servicios, el usuario acepta el uso que se hace de las cookies.</p>
                <p><strong>&iquest;C&oacute;mo se retira el consentimiento para la utilizaci&oacute;n de las cookies de este sitio Web?</strong></p>
                <p>El usuario tiene la opci&oacute;n de dejar de aceptar el uso de cookies en cualquier momento a trav&eacute;s de su navegador, pudiendo permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las opciones del navegador que utiliza en su dispositivo (ordenador, m&oacute;vil, tablet). Puede permitir o bloquear las cookies, as&iacute; como borrar sus datos de navegaci&oacute;n (incluidas las cookies) desde el navegador que utiliza. Debe consultar las opciones e instrucciones que ofrece su navegador para ello. Debe tener en cuenta que, si acepta las cookies de terceros, deber&aacute; eliminarlas desde la opci&oacute;n del navegador.</p>
                <p>Sin la habilitaci&oacute;n de las cookies necesarias y t&eacute;cnicas descritas en la presente pol&iacute;ticas de cookies es posible que algunos servicios o funcionalidades de la p&aacute;gina web no est&eacute;n disponibles.</p>
                <p>A continuaci&oacute;n se indican los enlaces donde se explica c&oacute;mo llevar a cabo este proceso en los principales navegadores de Internet:</p>
                <p>Chrome: https://support.google.com/chrome/answer/95647?hl=es&amp;co=GENIE.Platform=Desktop</p>
                <p>Explorer:https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internetexplorer-bca9446f-d873-78de-77ba-d42645fa52fc.</p>
                <p>Firefox:https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias? redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&amp;redirectlocale=es.</p>
                <p>Safari: https://support.apple.com/es-es/guide/safari/sfri11471/mac.</p>
                <p><strong>Actualizaci&oacute;n de la Pol&iacute;tica de Cookies</strong></p>
                <p>La Entidad ANAIS OSUNA DELAUNOIS actualizar&aacute; la presente pol&iacute;tica cuando sea necesario, ya sea porque lo exija una normativa o bien suprima o emplee nuevas cookies. Cualquier modificaci&oacute;n de estas condiciones ser&aacute; publicada en este sitio web.</p>
                <p>A partir del momento en que se ponga en conocimiento y a disposici&oacute;n del usuario las nuevas condiciones mediante la publicaci&oacute;n ante dicha, las nuevas pol&iacute;ticas de cookies ser&aacute;n de aplicaci&oacute;n, sustituyendo de forma autom&aacute;tica a las anteriormente publicadas.</p>
                <p>Si tiene dudas acerca de esta pol&iacute;tica de cookies, puede contactar con la entidad ANAIS OSUNA DELAUNOIS a trav&eacute;s del siguiente correo electr&oacute;nico ANAISDELAUNOIS1@GMAIL.COM.</p>
            </div>
        </div>
    </div>
);
export default CookiesPolicy;
