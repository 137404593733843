import React from "react";

const PrivacyPolicy = () => (
    <div className="pw_legals_container">
        <h4>AVISO LEGAL</h4>
        <p>De conformidad con lo dispuesto en los art&iacute;culos 10 y 11 de la Ley 34/ 2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico se pone a disposici&oacute;n de los usuarios y visitantes la informaci&oacute;n legal relativa a la entidad propietaria del sitio web ubicado en la direcci&oacute;n de Internet www. latelierbyanais.com.&nbsp;</p>
        <h6>Titular de la p&aacute;gina web.</h6>
        <p>Denominaci&oacute;n social: ANAIS OSUNA DELAUNOIS</p>
        <p>CIF/NIF: 41541276X</p>
        <p>Domicilio: CAMINO DE CAN PASTILLA N&ordm; 68 LOCAL C 07610 PALMA, BALEARES</p>
        <p>Direcci&oacute;n de correo electr&oacute;nico: ANAISDELAUNOIS1@GMAIL.COM</p>
        <p>Tel&eacute;fono: 661542138</p>
        <p>Actividad: Est&eacute;tica (depilaci&oacute;n, laser, tratamientos corporales y faciales)</p>
        <h6>Condiciones de uso:</h6>
        <p>Para navegar en esta p&aacute;gina web como visitante o para registrarse y poder acceder a los servicios ofrecidos por ANAIS OSUNA DELAUNOIS, se deber&aacute;n aceptar las siguientes condiciones de servicio y uso:</p>
        <ol>
            <li>El usuario asume las presentes condiciones generales de uso y servicio de la p&aacute;gina web www.latelierbyanais.com.</li>
        </ol>
        <p>Si el usuario no acepta las presentes condiciones, no podr&aacute; hacer uso de los servicios y contenidos de la p&aacute;gina web www.latelierbyanais.com.</p>
        <ol>
            <li>Las presentes condiciones de uso regulan el acceso y utilizaci&oacute;n de la p&aacute;gina web www.latelierbyanais.com La utilizaci&oacute;n de esta web le atribuye la condici&oacute;n de usuario desde el acceso e inicio de la navegaci&oacute;n en la misma. Desde el momento de acceso a cualquiera de sus contenidos, el usuario acepta de manera expresa las presentes condiciones generales. El usuario acepta las condiciones particulares aplicables de los diferentes servicios que oferta la entidad en la web a la que accede.</li>
            <li>A trav&eacute;s de la p&aacute;gina web, www.latelierbyanais.com se facilita a los usuarios el acceso y la utilizaci&oacute;n de diversa informaci&oacute;n y diversos servicios.</li>
            <li>El usuario debe ser mayor de edad para utilizar los servicios de la web www.latelierbyanais.com. Los menores de 18 a&ntilde;os s&oacute;lo podr&aacute;n utilizar los Servicios de la Web www.latelierbyanais.com bajo la supervisi&oacute;n de un progenitor o un tutor legal.</li>
            <li>El usuario se compromete a realizar un uso adecuado de los contenidos y servicios de la p&aacute;gina webwww. latelierbyanais.com. Este uso debe realizarse de conformidad con la Ley, las buenas costumbres, el orden p&uacute;blico y con lo dispuesto en las presentes condiciones generales.</li>
            <li>Con car&aacute;cter general, para la prestaci&oacute;n de los servicios y el acceso a la informaci&oacute;n de la p&aacute;gina web no se exige la previa suscripci&oacute;n o registro del usuario. No obstante, ANAIS OSUNA DELAUNOIS condiciona la utilizaci&oacute;n de algunos de los servicios, ofertados en la web a la previa cumplimentaci&oacute;n del correspondiente registro o formulario de recogida de datos de car&aacute;cter personal del usuario. El citado registro se efectuar&aacute; en la forma expresamente indicada en el propio servicio o en las condiciones particulares que, en su caso, lo regulen.</li>
            <li>El usuario asume la obligaci&oacute;n de no realizar ninguna actuaci&oacute;n que pueda da&ntilde;ar, inutilizar, hacer inaccesible o deteriorar los contenidos, los servicios y/o impedir un normal disfrute de la p&aacute;gina web www. latelierbyanais.com por otros usuarios. En particular, y a t&iacute;tulo meramente enunciativo y no limitativo, el usuario se compromete a:</li>
        </ol>
        <p>No realizar ninguna acci&oacute;n que pueda afectar, destruir, alterar, inutilizar o da&ntilde;ar datos de car&aacute;cter personal, programas o documentos electr&oacute;nicos que se encuentren en la web.</p>
        <p>No introducir, almacenar o difundir ning&uacute;n programa de ordenador, datos, virus o c&oacute;digo que sea susceptible de causar da&ntilde;os en la p&aacute;gina web, en cualquiera de los servicios, o en cualquiera de los equipos, sistemas o redes de la entidad, de cualquier otro usuario y/o de cualquier proveedor de la entidad.</p>
        <h6>Modificaciones.</h6>
        <p>La entidad se reserva el derecho a modificar, unilateralmente, en cualquier momento y sin previo aviso las presentes condiciones. En estos casos se proceder&aacute; a su publicaci&oacute;n y aviso con la m&aacute;xima antelaci&oacute;n posible. De igual modo, se reserva el derecho a modificar, unilateralmente, en cualquier momento y sin previo aviso, la presentaci&oacute;n y configuraci&oacute;n de la web.</p>
        <h6>Enlaces con terceros.</h6>
        <p>El presente Aviso Legal se refiere &uacute;nicamente a la p&aacute;gina web, y no se aplica a los enlaces o a las p&aacute;ginas web de terceros accesibles a trav&eacute;s de la p&aacute;gina web. La entidad no es responsable del contenido de ninguna de las p&aacute;ginas web de destino de un enlace, ni de ning&uacute;n enlace incluido en una p&aacute;gina web a la que se llegue desde la web de la entidad.</p>
        <h6>Propiedad Intelectual.</h6>
        <p>Todos los contenidos de la p&aacute;gina web son titularidad exclusiva de la entidad y, con car&aacute;cter enunciativo, que no limitativo, el dise&ntilde;o gr&aacute;fico, c&oacute;digo fuente, logos, textos, gr&aacute;ficos, ilustraciones, fotograf&iacute;as, y dem&aacute;s elementos que aparecen en la p&aacute;gina web. As&iacute; mismo los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en la p&aacute;gina web est&aacute;n protegidos por la Ley de propiedad intelectual e industrial. Corresponde a la entidad el ejercicio exclusivo de los derechos de explotaci&oacute;n de la propiedad intelectual mencionada, en cualquier forma y, en especial, los derechos de reproducci&oacute;n, distribuci&oacute;n y comunicaci&oacute;n p&uacute;blica. El usuario tiene prohibido cualquier uso no consentido total o parcial de cualquiera de los contenidos de la p&aacute;gina web que integran los derechos de propiedad intelectual o industrial de la entidad sobre la p&aacute;gina y/o sus contenidos.</p>
        <p>La entidad se reserva el derecho a realizar cualquier tipo de acci&oacute;n legal contra cualquier usuario que realice una acci&oacute;n que implique reproducci&oacute;n, distribuci&oacute;n, comercializaci&oacute;n, transformaci&oacute;n y, en general, cualquier otra forma de explotaci&oacute;n, por cualquier procedimiento, de todo o parte de los contenidos de la p&aacute;gina web, y que constituya una infracci&oacute;n de los derechos de propiedad intelectual y/o industrial de la misma.</p>
        <h6>Notificaciones.</h6>
        <p>A los efectos de las presentes condiciones generales, y para cualquier comunicaci&oacute;n que sea precisa entre la entidad y el usuario, este &uacute;ltimo deber&aacute; hacer uso del correo electr&oacute;nico ANAISDELAUNOIS1@GMAIL.COM. Las comunicaciones de la entidad al usuario se realizar&aacute;n conforme a los datos de car&aacute;cter personal aportados por este &uacute;ltimo al registrarse en la p&aacute;gina web www.latelierbyanais.com El usuario acepta expresamente y para todas las comunicaciones relacionadas con la utilizaci&oacute;n de la p&aacute;gina web y/o la contrataci&oacute;n de los servicios ofertados en la misma, la utilizaci&oacute;n del correo electr&oacute;nico como procedimiento v&aacute;lido para la remisi&oacute;n de estas comunicaciones.</p>
        <h6>Legislaci&oacute;n aplicable y sumisi&oacute;n a fuero.</h6>
        <p>Las presentes condiciones se regir&aacute;n por la legislaci&oacute;n espa&ntilde;ola, que ser&aacute; de aplicaci&oacute;n en lo no dispuesto en este contrato en materia de interpretaci&oacute;n, validez y ejecuci&oacute;n. Las partes renuncian expresamente al fuero que les pudiera corresponder y someten expresamente a los Juzgados y Tribunales de Palma de Mallorca para resolver cualquier controversia que pueda surgir en la interpretaci&oacute;n o ejecuci&oacute;n de las presentes condiciones contractuales.</p>

        <h4>POL&Iacute;TICA DE PRIVACIDAD</h4>

        <p>De conformidad con lo dispuesto en el Reglamento General de Protecci&oacute;n de Datos RGPD UE 679/2016 y la Ley Org&aacute;nica 3/2018 de 5 de Diciembre, de Protecci&oacute;n de Datos Personales y Garant&iacute;a de los Derechos Digitales, se le facilita la siguiente informaci&oacute;n del tratamiento de sus datos de car&aacute;cter personal:</p>
        <h6>&iquest;Qui&eacute;n es responsable del tratamiento de sus datos personales?</h6>
        <p>Responsable del tratamiento: ANAIS OSUNA DELAUNOIS, NIF/CIF: 41541276X, Direcci&oacute;n: CAMINO DE CAN PASTILLA N&ordm; 68 LOCAL C, CP: 07610, PALMA, Tel&eacute;fono 661542138, Email: ANAISDELAUNOIS1@GMAIL. COM. Delegado de protecci&oacute;n de datos: contacto: / http://www.protecmir.com / email: protecmirlegal@protecmir.com.</p>
        <h6>&iquest;Qu&eacute; datos tratamos?</h6>
        <p>En ANAIS OSUNA DELAUNOIS tratamos los datos personales facilitados por usted a trav&eacute;s de la cumplimentaci&oacute;n de los formularios habilitados a tal efecto en esta p&aacute;gina web, los datos personales derivados de la prestaci&oacute;n o contrataci&oacute;n de nuestros servicios o productos y/o los datos personales derivados de la relaci&oacute;n comercial que usted mantiene con nosotros. Adem&aacute;s, tratamos los datos personales generados en el contexto de su actividad en nuestra p&aacute;gina web, dentro de cuya categor&iacute;a se incluyen sus datos de navegaci&oacute;n obtenidos a trav&eacute;s de la p&aacute;gina web.</p>
        <p>Es importante para nosotros mantener el registro de sus datos personales actualizado. Usted tiene la obligaci&oacute;n de mantenernos informados de cualquier cambio o error en sus datos personales tan pronto como sea posible poni&eacute;ndose en contacto con nosotros en el correo electr&oacute;nico: ANAISDELAUNOIS1@GMAIL.COM.</p>
        <h6>&iquest;Con qu&eacute; finalidad tratamos sus datos de car&aacute;cter personal?</h6>
        <p>Finalidad: ANAIS OSUNA DELAUNOIS podr&aacute; tratar sus datos personales para las siguientes finalidades:</p>
        <p>- Gesti&oacute;n y mantenimiento de los servicios proporcionados a trav&eacute;s de la p&aacute;gina web.</p>
        <p>-Gesti&oacute;n y atenci&oacute;n de las consultas y solicitudes de informaci&oacute;n realizadas por los usuarios a trav&eacute;s del formulario de contacto. La direcci&oacute;n de correo electr&oacute;nico y los datos de car&aacute;cter personal que usted nos proporciona a trav&eacute;s del formulario de contacto de la p&aacute;gina web ser&aacute;n utilizados exclusivamente para atender las consultas que nos plantee por este medio.</p>
        <p>- Cumplimiento de las obligaciones legales aplicables.</p>
        <p>- Env&iacute;o de comunicaciones comerciales y boletines informativos, as&iacute; como publicidad de nuestros servicios y productos.</p>
        <p>- Gesti&oacute;n de la contrataci&oacute;n de nuestros servicios y/o productos.</p>
        <p>- Gesti&oacute;n de las solicitudes de informaci&oacute;n o solicitudes de presupuestos de nuestros servicios o productos.</p>
        <p>- Realizar encuestas para mejorar nuestros servicios.</p>
        <p>- Registro en el &aacute;rea de usuarios.</p>
        <p>-Recepci&oacute;n y gesti&oacute;n de su Curr&iacute;culum Vitae para participar en los procesos de selecci&oacute;n de personal de la entidad.</p>
        <p>- Gesti&oacute;n de la participaci&oacute;n en actividades y concursos organizados por la entidad.</p>
        <p>- Gesti&oacute;n del club de socios.</p>
        <p>- Gesti&oacute;n y tramitaci&oacute;n de las denuncias presentadas a trav&eacute;s del canal de denuncias interno.</p>
        <p>-En caso de haberlo consentido y con el fin de poder ofrecerle servicios de acuerdo con sus intereses sus datos personales pueden ser utilizados para elaborar un perfil comercial. No se tomar&aacute;n decisiones automatizadas en base a dicho perfil.</p>
        <h6>&iquest;Cu&aacute;nto tiempo conservaremos sus datos de car&aacute;cter personal?</h6>
        <p>Conservamos sus datos de car&aacute;cter personal en nuestros sistemas y archivos el tiempo necesario para llevar a cabo los fines del tratamiento, y para cumplir con lo dispuesto en la legislaci&oacute;n aplicable. Se conservar&aacute;n sus datos personales mientras exista una relaci&oacute;n contractual y/o comercial con usted, o mientras usted no ejerza su derecho de supresi&oacute;n y/o limitaci&oacute;n del tratamiento de sus datos. El periodo de conservaci&oacute;n de los datos personales variar&aacute; en funci&oacute;n de los fines del tratamiento y con car&aacute;cter general:</p>
        <p>-Los datos personales que nos proporciona en los supuestos de contrataci&oacute;n de nuestros servicios o productos ser&aacute;n conservados durante la relaci&oacute;n contractual y, una vez finalizada la misma, durante el plazo de prescripci&oacute;n de las acciones legales que se puedan derivar de la misma.</p>
        <p>-Los datos personales que nos proporciona para gestionar solicitudes de informaci&oacute;n o consultas a trav&eacute;s del formulario de contacto se conservar&aacute;n mientras usted no solicite su supresi&oacute;n o cancelaci&oacute;n.</p>
        <p>-Los datos personales que nos proporciona para suscribirse a nuestro Newsletter o boletines informativos se conservar&aacute;n mientras usted no solicite su supresi&oacute;n, oposici&oacute;n y/o limitaci&oacute;n.</p>
        <ul>
            <li>Los datos personales que nos proporciona mediante el env&iacute;o del curriculum vitae se conservar&aacute;n durante un plazo no superior a 1 a&ntilde;o.</li>
        </ul>
        <p>-Los datos personales que nos proporciona para participar en nuestras actividades y concursos se conservar&aacute;n mientras usted no solicite su supresi&oacute;n o cancelaci&oacute;n.</p>
        <ul>
            <li>Los datos personales que nos proporciona a trav&eacute;s del canal de denuncias se conservar&aacute;n durante un plazo no superior a 3 meses. .</li>
        </ul>
        <p>-Los datos personales obtenidos de su navegaci&oacute;n y h&aacute;bitos de consumo, as&iacute; como el perfil comercial obtenido, se conservar&aacute;n mientras usted no solicite su supresi&oacute;n o cancelaci&oacute;n.</p>
        <p>Sus datos de car&aacute;cter personal ser&aacute;n conservados mientras sean &uacute;tiles para las finalidades indicadas, y, en cualquier caso, durante los plazos legales y el tiempo necesario para atender a posibles responsabilidades nacidas del tratamiento.</p>
        <h6>Seguridad de los datos.</h6>
        <p>Disponemos de medidas de seguridad t&eacute;cnicas y organizativas adecuadas para proteger sus datos personales contra el tratamiento no autorizado o il&iacute;cito y contra su p&eacute;rdida, destrucci&oacute;n o da&ntilde;o accidental, asegurando la integridad y confidencialidad de los mismos. Las medidas de seguridad t&eacute;cnicas y organizativas implantadas permiten: garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento; restaurar la disponibilidad y el acceso a los datos de car&aacute;cter personal de forma r&aacute;pida en caso de incidente f&iacute;sico o t&eacute;cnico; y verificar, evaluar y valorar de forma regular la eficacia de las medidas t&eacute;cnicas y organizativas implantadas para garantizar la seguridad del tratamiento.</p>
        <p>Estas medidas de seguridad t&eacute;cnicas y organizativas han sido dise&ntilde;adas teniendo en cuenta nuestra infraestructura de TI, el estado de la t&eacute;cnica de acuerdo con los est&aacute;ndares y pr&aacute;cticas actuales, el coste de la aplicaci&oacute;n y la naturaleza, &aacute;mbito, contexto y fines del tratamiento, as&iacute; como los riesgos de diversa probabilidad y gravedad que entra&ntilde;a el tratamiento para sus datos personales.</p>
        <h6>&iquest;Cu&aacute;l es la legitimaci&oacute;n para el tratamiento de sus datos?</h6>
        <p>Legitimaci&oacute;n: La legitimaci&oacute;n para tratar sus datos de car&aacute;cter personal est&aacute; basada en:</p>
        <p>-La ejecuci&oacute;n y mantenimiento de una relaci&oacute;n contractual y comercial con usted, como, por ejemplo la contrataci&oacute;n de productos y servicios de la entidad, gesti&oacute;n y solicitud de presupuestos de los productos y/o servicios de la entidad, todo ello de conformidad con lo dispuesto en el art&iacute;culo 6.1.B del RGPD (UE) 679/2016 y la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDPGDD).</p>
        <p>-Su consentimiento expreso para una o varias finalidades, como por ejemplo el env&iacute;o de comunicaciones publicitarias o boletines informativos propios o de terceros, gesti&oacute;n de env&iacute;os de curriculum vitae, participaci&oacute;n en actividades o concursos, todo ello de conformidad con lo dispuesto el art&iacute;culo 6.1.A del RGPD (UE) 679/2016 y la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDPGDD).</p>
        <p>-El cumplimiento de distintas obligaciones legales, todo ello de conformidad con lo dispuesto en el art&iacute;culo 6.1 .C del RGPD (UE) 679/2016 y la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDPGDD).</p>
        <p>- La satisfacci&oacute;n de intereses leg&iacute;timos perseguidos por el responsable del tratamiento o por un tercero, por ejemplo, por motivos de seguridad, para mejorar nuestros servicios y o para gestionar sus solicitudes o consultas.</p>
        <p>Durante el proceso de recogida de datos, y en cada lugar de la web en el que se soliciten datos de car&aacute;cter personal, el usuario ser&aacute; informado, ya sea mediante un hiperv&iacute;nculo, ya sea mediante la inclusi&oacute;n de las menciones oportunas en el propio formulario, del car&aacute;cter obligatorio o no de la recogida de sus datos de car&aacute;cter personal.</p>
        <p>Los datos personales solicitados en los formularios de la p&aacute;gina web son, con car&aacute;cter general, obligatorios (salvo que en el campo requerido se especifique lo contrario) para cumplir con las finalidades establecidas. Por lo tanto, si no se facilitan los datos personales solicitados, o no se facilitan correctamente, no podr&aacute;n atenderse las mismas.</p>
        <p>Existe la obligaci&oacute;n de facilitar sus datos de car&aacute;cter personal en los supuestos de contrataci&oacute;n de un servicio o producto, y/o en aquellos supuestos de solicitud de presupuestos u ofertas.</p>
        <p>El env&iacute;o de comunicaciones publicitarias, newsletter o boletines informativos de nuestros productos y servicios est&aacute; basado en el consentimiento que se le solicita, sin que en ning&uacute;n caso la retirada de este consentimiento condicione la relaci&oacute;n contractual o comercial que usted mantiene con nosotros.</p>
        <p>Si usted nos ha autorizado a enviar publicidad de nuestros servicios y productos, sus datos de car&aacute;cter personal pueden ser utilizados para gestionar el env&iacute;o de ofertas publicitarias y boletines informativos a trav&eacute;s de medios electr&oacute;nicos. En estos casos resulta de aplicaci&oacute;n lo dispuesto en los art. 20 y 21 de la ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico, respecto al uso y tratamiento de sus datos de car&aacute;cter personal con el fin de gestionar el env&iacute;o de publicidad por medios electr&oacute;nicos.</p>
        <p>En caso de que haya marcado la opci&oacute;n de recibir publicidad, o si se ha suscrito a nuestro newsletter o bolet&iacute;n informativo, usted puede cancelar esta opci&oacute;n en cualquier momento.</p>
        <h6>&iquest;A qu&eacute; destinatarios se comunicar&aacute;n sus datos?</h6>
        <p>Destinatarios: Con car&aacute;cter general, sus datos de car&aacute;cter personal no se comunicar&aacute;n a ning&uacute;n tercero ajeno a la entidad, salvo obligaci&oacute;n legal. No obstante, le informamos de que terceros proveedores pueden tener acceso a sus datos personales en calidad de encargados del tratamiento, en el marco de la prestaci&oacute;n de un servicio para la entidad responsable del tratamiento. Le informamos de que puede solicitar la relaci&oacute;n completa de los destinatarios que podr&aacute;n recibir sus datos personales como encargados de tratamiento o como terceros receptores por transferencia en el correo electr&oacute;nico: ANAISDELAUNOIS1@GMAIL.COM.Adem&aacute;s de lo anterior, la entidad podr&aacute; realizar cesiones o comunicaciones de datos personales para atender sus obligaciones ante las Administraciones P&uacute;blicas en los casos que as&iacute; se requiera, de acuerdo con la legislaci&oacute;n vigente.</p>
        <p>No hay previsi&oacute;n de transferencias internacionales de datos. No se toman decisiones de adecuaci&oacute;n, garant&iacute;as, normas corporativas vinculantes o situaciones espec&iacute;ficas aplicables.</p>
        <h6>&iquest;C&oacute;mo transferimos sus datos?</h6>
        <p>Transferencias internacionales de datos: Para llevar a cabo las actividades de tratamiento de datos detalladas anteriormente, podemos transferir los datos a pa&iacute;ses de fuera del Espacio Econ&oacute;mico Europeo (EEE), y almacenar dichos datos en bases de datos f&iacute;sicas o digitales gestionadas por entidades que act&uacute;en en nuestro nombre. La gesti&oacute;n de bases de datos y el tratamiento de los datos se limitan a los fines del tratamiento, y se llevan a cabo seg&uacute;n las leyes y normativas vigentes en materia de protecci&oacute;n de datos. En caso de que los datos se env&iacute;en fuera del EEE, la empresa utilizar&aacute; medidas contractuales adecuadas para garantizar la protecci&oacute;n de los datos, que pueden incluir, entre otros, contratos basados en las cl&aacute;usulas est&aacute;ndar de protecci&oacute;n de datos adoptadas por la Comisi&oacute;n Europea aplicables al env&iacute;o de datos personales fuera del EEE.</p>
        <p>Derechos: Usted tiene derecho a obtener el acceso a sus datos personales, as&iacute; como a solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando, entre otros motivos, los datos que ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, usted puede solicitar la limitaci&oacute;n del tratamiento de sus datos, en cuyo caso &uacute;nicamente los conservaremos para el ejercicio o defensa de reclamaciones. Asimismo, y por motivos relacionados con su situaci&oacute;n particular, usted puede oponerse al tratamiento de sus datos, en cuyo caso su informaci&oacute;n personal dejar&aacute; de tratarse para aquellas finalidades respecto de las que haya manifestado su oposici&oacute;n. Cuando sea t&eacute;cnicamente posible, usted puede solicitar la portabilidad de sus datos a otro responsable del tratamiento. Para ejercitar estos derechos, de conformidad con la legislaci&oacute;n vigente, usted puede dirigirse por correo postal, adjuntando copia de un documento acreditativo de su identidad (DNI), a ANAIS OSUNA DELAUNOIS en CAMINO DE CAN PASTILLA N&ordm; 68 LOCAL C, CP: 07610, PALMA o al email ANAISDELAUNOIS1@GMAIL.COM. Usted tiene derecho a presentar una Reclamaci&oacute;n ante la Autoridad de Control: Agencia Espa&ntilde;ola de Protecci&oacute;n Datos (www.agpd.es). Procedencia de los Datos de</p>
        <p>Car&aacute;cter Personal: el propio interesado.</p>
        <p>Usted acepta expresamente la inclusi&oacute;n de los datos de car&aacute;cter personal recabados durante la navegaci&oacute;n en la p&aacute;gina web y/o proporcionados mediante la cumplimentaci&oacute;n de cualquier formulario, as&iacute; como los derivados de una posible relaci&oacute;n comercial, en los ficheros automatizados de datos de car&aacute;cter personal de la entidad.</p>
        <p>La entidad garantiza la confidencialidad de los datos de car&aacute;cter personal de los usuarios. No obstante, la entidad revelar&aacute; a las autoridades p&uacute;blicas competentes los datos personales y cualquier otra informaci&oacute;n que est&eacute; en su poder o sea accesible a trav&eacute;s de sus sistemas y sea requerida, de conformidad con las disposiciones legales y reglamentarias aplicables al caso. Los datos de car&aacute;cter personal pueden ser conservados en los ficheros titularidad de ANAIS OSUNA DELAUNOIS incluso una vez finalizadas las relaciones comerciales formalizadas a trav&eacute;s de la p&aacute;gina web de la entidad, exclusivamente para los fines indicados anteriormente y, en todo caso, durante los plazos legalmente establecidos, a disposici&oacute;n de autoridades administrativas o judiciales.</p>
        <h6>Uso de redes sociales.</h6>
        <p>Cuando usted interact&uacute;a con nuestra p&aacute;gina web a trav&eacute;s de diversas redes sociales, como cuando se conecta o nos sigue o comparte nuestro contenido en redes sociales (Facebook, Twitter, LinkedIn, Instagram u otras), podemos recibir informaci&oacute;n procedente de dichas redes sociales, incluida informaci&oacute;n sobre su perfil, ID de usuario asociada a su cuenta en las redes sociales, y cualquier otra informaci&oacute;n p&uacute;blica que usted permita compartir con terceros en la red social.</p>
        <p>La entidad utiliza las redes sociales con la finalidad de informar sobre los servicios que ofrece, as&iacute; como de cualquier otra actividad o evento que se realice y se quiera dar publicidad, pero en ning&uacute;n momento obtendr&aacute; de las mismas datos personales de los usuarios que interact&uacute;en en ellas, a menos que haya autorizaci&oacute;n expresa.</p>
        <p>Estos datos, solo son utilizados dentro de la propia red social y no son incorporados a ning&uacute;n sistema de tratamiento.</p>
        <p>Las redes sociales tienen sus propias condiciones de uso y pol&iacute;ticas de privacidad que usted est&aacute; obligado a tener en cuenta y a observar su cumplimiento si hace uso de ellas.</p>
    </div>
);
export default PrivacyPolicy;
