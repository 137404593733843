export default {
    biopeeling: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/biopeeling-e50f8634-69fd-4560-9887-7139d1d80e0f.png",
                width: 1,
                height: 0.6483516483516484,
                alt: "Cest Fini"
            },
            {
                src: "/static/biopeeling-0e6386d7-f8f7-4702-a9e3-5e3bf58fa822.png",
                width: 1,
                height: 0.5772357723577236,
                alt: "Cest Fini"
            },
            {
                src: "/static/biopeeling-598dd76c-0689-43c3-aec6-bd625e38de12.png",
                width: 1,
                height: 0.568,
                alt: "Cest Fini"
            },
            {
                src: "/static/biopeeling-05f6489f-2356-4bd9-ba39-6686543e4110.png",
                width: 1,
                height: 0.5675675675675675,
                alt: "Cest Fini"
            },
            {
                src: "/static/biopeeling-05f107fc-0562-47c0-814c-00c2db72b773.png",
                width: 1,
                height: 0.5671406003159558,
                alt: "Cest Fini"
            }
        ]
    },
    espejodiagnostico: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/espejodiagnostico-0ffbda75-7dd2-4429-ab7c-92353407dc67.png",
                width: 1,
                height: 0.9775910364145658,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-4b4cf632-fd29-4ba9-9e9f-408b426aabff.png",
                width: 1,
                height: 0.5792880258899676,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-1f9fe0f6-df2d-4ad4-ae3d-06b85dbf19e0.png",
                width: 1,
                height: 0.5653543307086614,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-52f222b4-48ea-46c2-9b01-b7dd51a12f74.png",
                width: 1,
                height: 0.5926544240400667,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-5dc22eb2-ec66-40fa-a4a9-3e13709f38d0.png",
                width: 1,
                height: 0.627151051625239,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-8c455e49-cda0-433a-80f8-4be56a242bde.png",
                width: 1,
                height: 0.6114081996434938,
                alt: "Cest Fini"
            },
            {
                src: "/static/espejodiagnostico-df45d250-d92f-4607-8013-0a9d2bbd385f.png",
                width: 1,
                height: 0.5675675675675675,
                alt: "Cest Fini"
            }
        ]
    },
    hyaluronpen: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/hyaluronpen-813a887b-903c-45fb-8440-3d11d6fe3adf.png",
                width: 1,
                height: 1.3671497584541064,
                alt: "Cest Fini"
            },
            {
                src: "/static/hyaluronpen-115b7454-c915-409f-b018-6c645578506c.png",
                width: 1,
                height: 1.5544554455445545,
                alt: "Cest Fini"
            },
            {
                src: "/static/hyaluronpen-555b8f98-b6ca-4282-a143-5048e87956d9.png",
                width: 1,
                height: 1.0818181818181818,
                alt: "Cest Fini"
            },
            {
                src: "/static/hyaluronpen-412c878a-9531-49bd-b7ce-54cd375a5ce9.png",
                width: 1,
                height: 0.5890909090909091,
                alt: "Cest Fini"
            },
            {
                src: "/static/hyaluronpen-2e9094c1-4368-48a6-8d55-cbc8480cbad0.png",
                width: 1,
                height: 1.5707317073170732,
                alt: "Cest Fini"
            },
            {
                src: "/static/hyaluronpen-fa42f8fd-fb3a-4f2e-b91b-59c1885e13db.png",
                width: 1,
                height: 0.6899766899766899,
                alt: "Cest Fini"
            }
        ]
    },
    tratamientosspa: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/tratamientosspa-b901fdbd-6f99-496c-8c50-db5f123adaaf.png",
                width: 1,
                height: 0.9951923076923077,
                alt: "Cest Fini"
            },
            {
                src: "/static/tratamientosspa-b7b703b2-957d-47a9-9fbd-86df8275752f.jpg",
                width: 1,
                height: 0.6691919191919192,
                alt: "Cest Fini"
            },
            {
                src: "/static/tratamientosspa-4d2bacd4-0e73-4281-a745-168b48dd01f4.jpg",
                width: 1,
                height: 1.4955089820359282,
                alt: "Cest Fini"
            }
        ]
    },
    metodopiroche: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/metodopiroche-d6e049bf-88b0-4c64-9e9b-02a62d06620c.png",
                width: 1,
                height: 0.5703001579778831,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-b8ee925f-5f12-40df-954d-8c6b7afd8c6c.png",
                width: 1,
                height: 0.5792880258899676,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-7df70a9c-9585-4bf5-9ee1-0e139bd5baa4.png",
                width: 1,
                height: 0.5866666666666667,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-b286575b-3f7f-48b4-a2b7-9a4e9b2dd901.png",
                width: 1,
                height: 0.5689381933438986,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-4ea5954f-2417-4655-b724-ace8a396993b.png",
                width: 1,
                height: 0.6379928315412187,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-cb089643-5c20-42b0-abab-7340ddece4d3.png",
                width: 1,
                height: 0.6111111111111112,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-3af1df05-875b-4026-8465-52c5d4eb94da.png",
                width: 1,
                height: 0.5705128205128205,
                alt: "Cest Fini"
            },
            {
                src: "/static/metodopiroche-01c2505d-5917-4419-b5ca-17c600274e1e.png",
                width: 1,
                height: 0.7041420118343196,
                alt: "Cest Fini"
            }
        ]
    },
    ondaschoque: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/ondaschoque-77b63287-7a15-4ef1-925a-51cfbbf5208e.png",
                width: 1,
                height: 0.7971014492753623,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-7709a85c-7374-45fd-855a-81996814d435.png",
                width: 1,
                height: 0.6104006820119352,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-1edfaa46-e1e9-442c-8eed-f4cbba454c0c.png",
                width: 1,
                height: 0.5565149136577708,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-d5023c5a-e6b4-4f27-b2ea-869da76f0058.png",
                width: 1,
                height: 0.6242371403661726,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-4e126636-43de-438d-9fdf-bebe1db1d621.png",
                width: 1,
                height: 0.5626477541371159,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-18acccd4-a1ed-457b-aa13-107d3dd005ab.png",
                width: 1,
                height: 0.5850515463917526,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-32582500-8ea7-4af6-91a6-0500315b74f6.png",
                width: 1,
                height: 0.5832656376929326,
                alt: "Cest Fini"
            },
            {
                src: "/static/ondaschoque-691f2a76-c61c-4c47-b9b2-0963bbb3e50c.png",
                width: 1,
                height: 0.9378238341968912,
                alt: "Cest Fini"
            }
        ]
    },
    osteopatia: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/osteopatia-10a7929b-ec52-4072-9f62-7956d7292ee3.jpg",
                width: 1,
                height: 0.6868468468468468,
                alt: "Cest Fini"
            }
        ]
    },
    presoterapia: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/presoterapia-a5d1f528-2dca-4d8a-9b92-709adf5ff925.png",
                width: 1,
                height: 0.5774193548387097,
                alt: "Cest Fini"
            },
            {
                src: "/static/presoterapia-ccb6d4bb-fdb0-4049-9192-d7a51432f215.png",
                width: 1,
                height: 0.5543307086614173,
                alt: "Cest Fini"
            },
            {
                src: "/static/presoterapia-ecc73bce-f807-45cb-a3ce-6d884b1319df.png",
                width: 1,
                height: 0.5576619273301737,
                alt: "Cest Fini"
            }
        ]
    },
    radiofrecuenciacorporal: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/radiofrecuenciacorporal-c8720c3f-0646-4f09-912e-21f7d012e808.JPG",
                width: 1,
                height: 0.6666666666666666,
                alt: "Cest Fini"
            },
            {
                src: "/static/radiofrecuenciacorporal-760c87f1-2bc7-4a5c-843a-d3a362950709.png",
                width: 1,
                height: 1.7459677419354838,
                alt: "Cest Fini"
            },
            {
                src: "/static/radiofrecuenciacorporal-08bfc4e1-07b1-4f31-a070-b485f30370ee.png",
                width: 1,
                height: 1.4493927125506072,
                alt: "Cest Fini"
            }
        ]
    },
    radiofrecuenciafacial: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/radiofrecuenciafacial-3d97c59f-4236-4129-8731-800be49b2a01.jpg",
                width: 1,
                height: 1.3340683572216097,
                alt: "Cest Fini"
            },
            {
                src: "/static/radiofrecuenciafacial-dfef43fd-cbf5-4fd7-a4c6-e29fa9877a1e.JPG",
                width: 1,
                height: 0.6666666666666666,
                alt: "Cest Fini"
            },
            {
                src: "/static/radiofrecuenciafacial-75029e9b-cc5b-40fc-88c5-ebcf7cd3125a.jpg",
                width: 1,
                height: 1.3340683572216097,
                alt: "Cest Fini"
            }
        ]
    },
    zionic: {
        title: null,
        subTitle: null,
        custom: true,
        photos: [
            {
                src: "/static/zionic-dd5d2b46-3e3b-4562-ba17-a334425127d7.png",
                width: 1,
                height: 0.5549738219895288,
                alt: "Cest Fini"
            },
            {
                src: "/static/zionic-bef5d2ef-596e-49cc-99a6-893b8c3182db.png",
                width: 1,
                height: 0.571619812583668,
                alt: "Cest Fini"
            },
            {
                src: "/static/zionic-1854ae37-997c-45e5-b340-0a5c0eb32a50.png",
                width: 1,
                height: 0.8282442748091603,
                alt: "Cest Fini"
            },
            {
                src: "/static/zionic-6d49ce74-267a-41b0-847f-11124a06f72f.png",
                width: 1,
                height: 0.5980528511821975,
                alt: "Cest Fini"
            },
            {
                src: "/static/zionic-393e234f-0560-4a85-bdda-af85913f6481.png",
                width: 1,
                height: 0.5611038107752957,
                alt: "Cest Fini"
            }
        ]
    }
};
